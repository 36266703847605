<template>
  <v-container>
    <v-row>
      <h1>Tweet</h1>
    </v-row>
    <v-row>
      <v-col cols="9">
        <v-text-field label="tweet" v-model="newTweet"></v-text-field>
      </v-col>
      {{ newTweet }}
      <v-col cols="3">
        <v-btn color="primary" @click="setTweets(newTweet)">送信</v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-container v-for="item in tweets" v-bind:key="item.id">
        {{ item.contents }} {{ item.date }} <v-btn color="primary" @click="updateTweets(item)">更新</v-btn> <v-btn color="primary" @click="deleteTweets(item)">削除</v-btn>
      </v-container>
    </v-row>
  </v-container>
</template>

<script>
// import axios from "axios";
import * as api from "@/api";

export default {
  name: "Tweet",

  data: () => ({
    tweets: [],
    newTweet: null,
  }),

  created: async function () {
    this.tweets = await api.getTweets();
    console.log(this.tweets);
    console.log(this.tweets[0].contents);
    //    this.tweets = JSON.parse(data.body);
    //    console.log(this.tweets.body.contents);
  },

  methods: {
    async setTweets(contents) {
      console.log(contents);
      const date = new Date();
      await api.setTweets(contents, date);
    },

    async updateTweets(tweet) {
      console.log(tweet);
      tweet.date = new Date();
      await api.updateTweets(tweet);
    },

    async deleteTweets(tweet) {
      console.log(tweet);
      await api.deleteTweets(tweet.id);
    },


  },
};
</script>
