<template>
  <v-container>
    <ul>
      <li>
        <router-link to="/todo">todo</router-link>
      </li>
      <li>
        <router-link to="/tweet">tweet</router-link>
      </li>
    </ul>
  </v-container>
</template>

<script>
// import axios from "axios";
// import * as api from "@/api";

export default {
  name: "Home",

  data: () => ({
  }),

  created: async function () {
  },
}
</script>
