import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home'
import Tweet from './views/Tweet'
import Todo from './views/Todo'


Vue.use(Router)

export default new Router({
    mode: 'history',
    routes: [
        {
            path: '/tweet',
            name: 'tweet',
            component: Tweet
        },
        {
            path: '/todo',
            name: 'todo',
            component: Todo
        },
        {
            path: '/',
            name: 'home',
            component: Home
        },
    ]
})