<template>
  <v-container>
    <v-row>
      <h1>Todo List1</h1>
    </v-row>
    <v-row>
      <v-col cols="7">
        <v-text-field label="タスク12"></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="date"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date"
              label="いつまで(納期)"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="date" no-title scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
            <v-btn text color="primary" @click="$refs.menu.save(date)">
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="2">
        <v-btn color="primary"> 登録 </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">タスク</th>
                <th class="text-left">納期</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in tasks" :key="item.title">
                <td>{{ item.title }}</td>
                <td>{{ item.date }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-row>tweets: {{tweets.contents}} </v-row>
    <!-- <v-row>tweets: {{tweets.body}} </v-row> -->
  </v-container>
</template>

<script>
// import axios from "axios";
import * as api from "@/api";

export default {
  name: "Home",

  data: () => ({
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu: false,

    tasks: [
      { title: "旅行の予約", date: "2022-5-10" },
      { title: "車定期点検", date: "2022-7-10" },
      { title: "ドライブレコーダーのメモリ交換", date: "2022-4-12" },
    ],

    tweets: {},
  }),

  created: async function () {
    this.tasks = await api.getTasks();
    this.tweets = await api.getTweets();
    console.log(this.tweets);
    console.log(this.tweets.contents);
//    this.tweets = JSON.parse(data.body);
//    console.log(this.tweets.body.contents);
  },
};
</script>
