import axios from "axios";

// const baseURL = process.env.VUE_APP_HOST;
// (!todo) 暫定 local開発のBackend URL
// const baseURL = "http://localhost:3000";
// (!todo) 暫定 ProductionのBackend URL
 const baseURL = "https://www.masawebsite.net";
//  const rambdaBaseURL = "https://api.masawebsite.net";


const basePath = "api/";

console.log("baseURL");
console.log(baseURL);

const axiosBase = axios.create({
   baseURL
});

// const axiosRambdaBase = axios.create({
//    rambdaBaseURL
// });


/************タスクの取得 *************/
export const getTasks = async () => {
   try {
      const result = await axiosBase.get(basePath + "tasks");
      return result.data;
   } catch (error) {
      console.log(error);
   }
};

export const getTweets = async () => {
   try {
      // const result = await axiosRambdaBase.get("tweets");
      const result = await axios.get("https://api.masawebsite.net/tweets");
      return result.data;
   } catch (error) {
      console.log(error);
   }
};

export const setTweets = async (contents, date) => {
   try {
      // const result = await axiosRambdaBase.get("tweets");
      const result = await axios.post("https://api.masawebsite.net/tweets",
      {
         contents: contents,
         date: date
      });
      return result.data;
   } catch (error) {
      console.log(error);
   }
};

export const updateTweets = async (tweet) => {
   try {
      // const result = await axiosRambdaBase.get("tweets");
      const result = await axios.put("https://api.masawebsite.net/tweets" + "/" + tweet.id.toString(),
//      const result = await axios.put("https://api.masawebsite.net/tweets",
      {
         contents: "update: " + tweet.contents,
         date: tweet.date
      });
      return result.data;
   } catch (error) {
      console.log(error);
   }
};

export const deleteTweets = async (id) => {
   try {
      // const result = await axiosRambdaBase.get("tweets");
      const result = await axios.delete("https://api.masawebsite.net/tweets" + "/" + id.toString(), {} );
      return result.data;
   } catch (error) {
      console.log(error);
   }
};
